.toolbar[data-v-d4605974] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.toolbar .input-container[data-v-d4605974] {
  white-space: nowrap;
  margin-right: 10px;
}
.toolbar .input-container .input[data-v-d4605974] {
  min-width: 150px;
}
.toolbar .material_tool_checkbox[data-v-d4605974] {
  margin-bottom: 0;
}
.projectLink .el-select-dropdown__item[data-v-d4605974] {
  height: 59px !important;
}
.projectLink .el-select-dropdown__item > p[data-v-d4605974] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-d4605974] {
  font-size: 12px;
  line-height: 1em;
}
@media (min-width: 640px) {
.input-container[data-v-d4605974] {
    display: flex;
    margin-right: 8px;
}
.right-container[data-v-d4605974] {
    float: right;
}
}
@media (max-width: 640px) {
.input-container[data-v-d4605974] {
    width: 100%;
}
.right-container[data-v-d4605974] {
    float: none;
    padding-top: 12px;
}
}
