.table[data-v-e5b4857e] {
  margin: 0;
  width: 100%;
  height: 100%;
}
[data-v-e5b4857e] .ht_clone_left {
  z-index: 1;
}
[data-v-e5b4857e] .ht_clone_top {
  z-index: 1;
}
[data-v-e5b4857e] .ht_clone_top_inline_start_corner.ht_clone_top_left_corner {
  z-index: 1;
}
[data-v-e5b4857e] .ht_master {
  padding-bottom: 15px;
}
[data-v-e5b4857e] .ht_clone_inline_start.ht_clone_left.handsontable {
  display: inline;
}
